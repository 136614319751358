import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIsSignedIn, useUserEmail, useUserName } from '../util/DataStore';
import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { signOut } from './SignInScreen';
import { getApp } from '../fire';
import { getAuth } from 'firebase/auth';

const LogoutMenu = () => {
  const [userName] = useUserName();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    setAnchorEl(null);
    await signOut();
    navigate('/login');
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{ color: 'white' }}
      >
        {userName}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleClose}>Profile</MenuItem>
        <MenuItem onClick={handleClose}>My account</MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </div>
  );
};
export const SignInLink = () => {
  const navigate = useNavigate();
  const [isSignedIn, setIsSignedIn] = useIsSignedIn();
  const [, setUserName] = useUserName();
  const [, setUserEmail] = useUserEmail();

  // Listen to the Firebase Auth state and set the local state.
  useEffect(() => {
    const unregisterAuthObserver = getAuth(getApp()).onAuthStateChanged((user) => {
      setUserName(user?.displayName || '');
      setUserEmail(user?.email || '');
      setIsSignedIn(!!user);
    });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, [navigate, setIsSignedIn, setUserEmail, setUserName]);

  const onLoginPressed = () => {
    navigate(`/login`);
  };

  return isSignedIn ? <LogoutMenu /> : <Typography onClick={onLoginPressed}>Sign In</Typography>;
};
export default SignInLink;
