import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { useIsSignedIn } from '../util/DataStore';
import { Button } from '@mui/material';
import { getAuth } from 'firebase/auth';
import { getApp } from '../fire';

// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // We will display Google and Facebook as auth providers.
  signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID, firebase.auth.FacebookAuthProvider.PROVIDER_ID],
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false,
  },
};

export async function signOut() {
  return getAuth(getApp()).signOut();
}

function SignInScreen() {
  const [isSignedIn] = useIsSignedIn();

  if (!isSignedIn) {
    return (
      <div>
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={getAuth(getApp())} />
      </div>
    );
  }
  return (
    <div>
      <p>Welcome {getAuth(getApp()).currentUser?.displayName}!</p>
      <Button onClick={() => getAuth(getApp()).signOut()}>Sign Out</Button>
    </div>
  );
}

export default SignInScreen;
