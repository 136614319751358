export const formatYMD = (year: number, month: number, day: number) => {
  const s = `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`;
  return s;
};

export const formatDate = (d: Date) => {
  const year = d.getFullYear();
  const month = d.getMonth() + 1;
  const day = d.getDate();
  return formatYMD(year, month, day);
};
export const getDateString = () => {
  return formatDate(new Date());
};

export const validYMD = (s: string) => {
  return s.match(/^[0-9][0-9][0-9][0-9]-[0-9]+-[0-9]+$/);
};

export const validMDY = (s: string) => {
  return s.match(
    /^((0?[13578]|10|12)(-|\/)(([1-9])|(0[1-9])|([12])([0-9]?)|(3[01]?))(-|\/)((19)([0-9])(\d{1})|(20)([0123])(\d{1}))|(0?[2469]|11)(-|\/)(([1-9])|(0[1-9])|([12])([0-9]?)|(3[0]?))(-|\/)((19)([0-9])(\d{1})|(20)([0123])(\d{1})))$/
  );
};

export const convertMDY2YMD = (s: string) => {
  if (validMDY(s)) {
    let parts = s.replace(/\//g, '-').split('-');
    parts = parts.map((p) => (p.length === 1 ? `0${p}` : p));
    s = `${parts[2]}-${parts[0]}-${parts[1]}`;
    return s;
  } else {
    return s;
  }
};
export const convertYMD2MDY = (s: string) => {
  if (validYMD(s)) {
    let parts = s.replace(/\//g, '-').split('-');
    parts = parts.map((p) => (p.length === 1 ? `0${p}` : p));
    s = `${parts[1]}-${parts[2]}-${parts[0]}`;
    return s;
  } else {
    return s;
  }
};

const EmailRegexp = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);
export const validEmail = (s: string) => EmailRegexp.test(s);

export const formatPhoneNumber = (phoneNumberString: string) => {
  let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    //let intlCode = match[1] ? '+1 ' : '';
    return `${match[2]}-${match[3]}-${match[4]}`;
  }
  return '';
};

export const curlyReplace = (str: string, map: { [key: string]: any }) => {
  const regexp = /{([^{]+)}/g;
  return str.replace(regexp, function (ignore, key: string) {
    const v = String(map[key]);
    return v === null ? '' : v;
  });
};

/*eslint-disable no-new-func*/
export const saferEval = (str: string) => {
  return Function(`"use strict";return (${str})`)();
};
