import { getApp } from '../../src/fire';
import {
  getFirestore,
  collection,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  DocumentData,
  getDocs,
  query,
  FieldPath,
} from 'firebase/firestore';

/* **********************************************************************************
 * Note: This file is not tsx!  Do not add any dependencies that require tsx (react).
 *       In addtion, it uses the function based V9 web sdk api.
 * **********************************************************************************/

export async function getDocument<T = DocumentData>(coll: string, id: string) {
  const db = getFirestore(getApp());
  const snap = await getDoc(doc(db, coll, id));
  if (snap.exists()) return snap.data() as T;
  else return undefined;
}

export async function getField<T = string>(coll: string, id: string, field: string | string[]) {
  const db = getFirestore(getApp());
  const snap = await getDoc(doc(db, coll, id));
  if (snap.exists()) {
    const path = typeof field === 'string' ? new FieldPath(field) : new FieldPath(...field);
    return snap.get(path) as T;
  } else {
    return undefined;
  }
}

export async function getDocList<T = any>(coll: string): Promise<T[]> {
  const db = getFirestore(getApp());
  const collectionRef = collection(db, coll);
  const q = query(collectionRef);
  const docs = await getDocs(q);
  if (docs.empty) {
    console.log('no docs');
    return [];
  } else {
    const list: T[] = [];
    docs.forEach((doc) => list.push(doc.data() as T));
    return list;
  }
}

export async function updateDocument<T>(coll: string, id: string, content: T) {
  const db = getFirestore(getApp());
  await updateDoc(doc(db, coll, id), content);
}
export async function saveDocument<T>(coll: string, id: string, content: T) {
  const db = getFirestore(getApp());
  await setDoc(doc(db, coll, id), content);
}
