import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Checkbox,
  FormControlLabel,
} from '@mui/material';

import { ArgMap, ListConfig, FieldConfig } from './FormTypes';
import makeStyles from '@mui/styles/makeStyles';
import { FormValues } from './FormX';
import { FormData } from './UseForm';
import { UseDatum } from 'react-usedatum';
import { validEmail } from './FormUtils';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  label: {
    paddingRight: theme.spacing(2),
  },
}));

export interface EditConfigProps {
  open: boolean;
  title?: string;
  listConfig?: ListConfig;
  fieldConfig?: FieldConfig;
  values?: ArgMap;
  onSave?: (rows: ArgMap<any>, modified: boolean) => void;
  onCancel?: () => void;
}

export const [useEditConfig, setEditConfig] = UseDatum<EditConfigProps>({ open: false });
const FormEditRow = () => {
  const [editConfig, setEditConfig] = useEditConfig();
  const [showAll, setShowAll] = useState(false);
  const [formValues, setFormValues] = useState({} as ArgMap);
  const [errors, setErrors] = useState({} as ArgMap);
  const classes = useStyles();
  const values = editConfig.values || {};
  const listConfig = editConfig.listConfig;
  const fieldConfig = editConfig.fieldConfig || {};

  if (!listConfig) {
    return null;
  }
  const editcols = (showAll ? listConfig.editallcols : listConfig.editcols) || listConfig.editcols || [];

  const handleClose = () => {
    setEditConfig({ open: false });
    editConfig.onSave && editConfig.onSave(formValues, true);
  };
  const handleCancel = () => {
    setEditConfig({ open: false });
    editConfig.onCancel && editConfig.onCancel();
  };

  const handleShowAll = (event: any) => {
    const checked = event.target.checked;
    setShowAll(checked);
  };

  const validateField = (key: string, value: string) => {
    const config = fieldConfig[key] || {};
    let err = '';
    switch (config.type) {
      case 'email':
        if (!validEmail(value)) {
          return 'Invalid Email Address';
        }
        break;
      default:
        break;
    }
    if (!err && config.required && !value) {
      return `${config.label || config.key} required`;
    }
    return undefined;
  };
  const onChange = (key: string, value: string) => {
    const valid = validateField(key, value);
    setErrors((prior) => ({ ...prior, [key]: valid }));
    return true;
  };
  let hasError = false;
  for (const k in errors) {
    hasError = hasError || Boolean(errors[k]);
  }
  // console.log(JSON.stringify({ errors, hasError }));

  return (
    <FormData
      formValues={formValues}
      setFormValues={setFormValues}
      defaultValues={values}
      errors={errors}
      onSubmit={(data: ArgMap) => {
        console.log(`submitting ${JSON.stringify(data)})`);
      }}
      onChange={onChange}
    >
      <Dialog
        classes={{ paper: classes.root }}
        open={Boolean(editConfig.open)}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{`${editConfig?.title || 'Edit Values'}`}</DialogTitle>
        <DialogContent>
          <Grid container spacing={0} alignItems="center">
            {listConfig.editallcols && (
              <React.Fragment>
                <Grid item xs={6} className={classes.label} />
                <Grid item xs={6}>
                  <FormControlLabel
                    control={<Checkbox checked={showAll} onChange={handleShowAll} value={showAll} />}
                    labelPlacement="start"
                    label={'Show All Fields'}
                  />
                </Grid>
              </React.Fragment>
            )}
            <FormValues keys={editcols} fieldConfig={fieldConfig} />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button disabled={hasError} onClick={handleClose} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </FormData>
  );
};

export default FormEditRow;
