// Import the functions you need from the SDKs you need
import { FirebaseApp, initializeApp } from 'firebase/app';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyAWi6DxYSkjwTzMiRGHWuFM8Jodh73U204',
  authDomain: 'ourgatecontrol.firebaseapp.com',
  databaseURL: 'https://ourgatecontrol.firebaseio.com',
  projectId: 'ourgatecontrol',
  storageBucket: 'ourgatecontrol.appspot.com',
  messagingSenderId: '417219608499',
  appId: '1:417219608499:web:a2bdbdd6ba0577e4e315c6',
};

// Initialize Firebase
let app: FirebaseApp | undefined;
export const getApp = () => {
  if (app === undefined) {
    app = initializeApp(firebaseConfig);
  }
  return app;
};
