import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Title from '../components/Title';
import { useSelectedSite } from '../util/DataStore';
import { useDoc } from '../util/FirebaseHooks';
import { ArgMap, FieldConfig } from '../formx/FormTypes';
import DataList from '../formx/DataList';
interface UserConfig extends ArgMap {
  role: string;
  email: string;
  index?: string;
}

const listConfig = {
  name: '',
  idcol: 'index',
  dispcols: ['email', 'name', 'role'],
  editcols: ['email', 'name', 'role'],
};

const fieldConfig: FieldConfig = {
  role: {
    type: 'enum',
    title: 'Role',
    enums: [
      { key: 'user', title: 'User' },
      { key: 'guest', title: 'Guest' },
      { key: 'owner', title: 'Owner' },
      { key: 'disabled', title: 'Disabled' },
    ],
    required: true,
  },
  email: {
    type: 'email',
    title: 'Email',
    required: true,
  },
  name: {
    type: 'string',
    title: 'Name',
    required: true,
  },
};
export default function Site() {
  const location = useLocation();
  const [selectedSite, setSelectedSite] = useSelectedSite();
  const [site, updateSite] = useDoc('sites', selectedSite);
  useEffect(() => {
    const parts = location.pathname.split('/');
    const system = parts[2];
    setSelectedSite(system);
  }, [location, setSelectedSite]);

  const onRowSave = (values: ArgMap, modified: boolean) => {
    const index = values.index;
    if (index) {
      users[index] = values as UserConfig;
    } else {
      users.push(values as UserConfig);
    }
    const newUserList: { [email: string]: UserConfig } = {};
    users.forEach((u) => {
      delete u.index;
      newUserList[u.email] = u;
    });
    updateSite({ users: newUserList });
  };

  const onAddRow = () => {
    return { role: 'user', email: '' } as UserConfig;
  };

  const onDeleteRow = (values: ArgMap) => {
    if (!values.index) {
      return;
    }
    if (values.role === 'owner') {
      window.alert('Cannot delete user with Owner role');
      return;
    }
    users.splice(values.index, 1);
    const newUserList: { [email: string]: UserConfig } = {};
    users.forEach((u) => {
      delete u.index;
      newUserList[u.email] = u;
    });
    updateSite({ users: newUserList });
  };

  const users = (Object.values(site?.users || {}) as UserConfig[]).map((u, i) => {
    u.index = String(i);
    return u;
  });
  return (
    <React.Fragment>
      <Title>{site?.config?.name}</Title>
      <DataList<UserConfig>
        title="Site Users"
        listConfig={listConfig}
        fieldConfig={fieldConfig}
        canEdit={true}
        canDelete={true}
        showCount={false}
        rows={users}
        onSave={onRowSave}
        onAdd={onAddRow}
        onDelete={onDeleteRow}
        orderBy="name"
      />
    </React.Fragment>
  );
}
