import React from 'react';
import { getUserEmail } from '../util/DataStore';
import DataList from '../formx/DataList';
import { ArgMap, FieldConfig } from '../formx/FormTypes';
import { saveDocument, updateDocument } from '../util/FirebaseUtil';
import uuidv4 from 'short-uuid';
import { useDocList } from '../util/FirebaseHooks';
import { Site, SiteConfig } from '../shared/OurGateTypes';
import { CircularProgress } from '@mui/material';
import Title from '../components/Title';

const EmptySiteList: Site[] = [];
export default function SiteList() {
  const [sites] = useDocList<Site>('sites', EmptySiteList);
  const initialized = sites !== EmptySiteList;
  const email = getUserEmail();
  const admin = email === 'glenne@engel.org' || email === 'glenp@thepurdys.org';

  const onRowSave = (values: ArgMap, modified: boolean) => {
    const systemId = values['systemId'];
    const site = sites.find((s) => s.config.systemId === systemId);
    if (site) {
      updateDocument('sites', systemId, { config: values });
      //site.config = { ...site.config, ...values };

      //setSites(sites, true);
    } else {
      const newsite = {
        config: values as SiteConfig,
        users: { [email]: { email, name: 'Admin', role: 'owner' } },
      } as Site;
      saveDocument('sites', systemId, newsite);
      //setSites([...sites, newsite]);
    }
  };

  const onAddRow = () => {
    return { systemId: uuidv4.generate() } as ArgMap;
  };

  const listConfig = {
    name: 'foo',
    idcol: 'systemId',
    dispcols: ['name', 'address', 'systemId'],
    editcols: ['name', 'address', 'disableAutoOpen', 'deviceType', 'deviceId'],
  };

  const fieldConfig: FieldConfig = {
    name: {
      type: 'string',
      title: 'Name',
    },
    address: {
      type: 'string',
      title: 'Address',
    },
    disableAutoOpen: {
      type: 'checkbox',
      title: 'Disable Auto Open',
    },
    systemId: {
      type: 'string',
      title: 'Users',
      text: 'Edit Users',
      key: 'SystemId',
      // eslint-disable-next-line no-template-curly-in-string
      link: '/site/${value}',
    },
    deviceType: {
      type: 'string',
      title: 'Device Type',
    },
    deviceId: {
      type: 'string',
      title: 'Device Id',
    },
  };

  if (admin) {
    listConfig.dispcols = [...listConfig.dispcols, 'deviceType', 'deviceId'];
  }

  if (!initialized) {
    return <CircularProgress />;
  }

  if (initialized && sites.length === 0) {
    return (
      <>
        <Title>You do not have access to any sites at this time.</Title>
        <Title>To create a new site, send an email to info@entazza.net.</Title>
        <Title>To be added to an existing site, contact the local site administrator.</Title>
      </>
    );
  }

  return (
    <DataList<ArgMap>
      title="My Sites"
      listConfig={listConfig}
      fieldConfig={fieldConfig}
      canEdit={admin}
      showCount={false}
      rows={sites.map((site) => site.config)}
      onSave={onRowSave}
      onAdd={onAddRow}
    />
  );
}
