import { ArgMap } from '../formx/FormTypes';
import { useEffect, useMemo, useRef } from 'react';
import { getFirestore, collection, query, where, onSnapshot, FieldPath, doc, updateDoc } from 'firebase/firestore';
import { getUserEmail } from './DataStore';
import { getApp } from '../../src/fire';
import { UseDatum } from 'react-usedatum';
export const useDocList = <T extends any = ArgMap>(coll: string, def: T[]) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const [_useDocList, _setDocList] = useMemo(() => UseDatum<T[]>(def), [coll, def]);
  useEffect(() => {
    // Set up subscription for doc list changes
    const db = getFirestore(getApp());
    const collectionRef = collection(db, coll);
    const path = new FieldPath('users', getUserEmail(), 'role');
    const q = query(collectionRef, where(path, 'in', ['editor', 'owner']));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const docs: T[] = [];
      querySnapshot.forEach((doc) => {
        docs.push(doc.data() as T);
      });
      _setDocList(docs);
    });
    return () => unsubscribe();
  }, [_setDocList, coll]);

  return _useDocList();
};

export const useDoc = <T extends any = ArgMap>(coll: string, id: string) => {
  const idRef = useRef<string>('');
  idRef.current = id;

  // Create a UseDatum instance for this doc instance. id can be undefined initially
  // so stash it in a ref for later access by change callback
  const [_useDoc, _setDoc] = useMemo(
    () =>
      UseDatum<T | undefined>(undefined, async (current, prior) => {
        // The initial value is undefined so only rewrite to firestore after that
        if (prior !== undefined) {
          const db = getFirestore(getApp());
          await updateDoc(doc(db, coll, idRef.current), current as ArgMap);
        }
      }),
    [coll]
  );

  // Create a subscription for doc changes
  useEffect(() => {
    if (id && coll) {
      // Set up subscription for doc changes
      const db = getFirestore(getApp());
      const unsubscribe = onSnapshot(doc(db, coll, id), (doc) => {
        const data = doc.data() as T;
        _setDoc(data);
      });
      return () => unsubscribe();
    }
  }, [_setDoc, coll, id]);

  return _useDoc();
};
