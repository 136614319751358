import { Toolbar, IconButton, Typography, Badge } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { UseDatum } from 'react-usedatum';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import clsx from 'clsx';
import { useIsSignedIn } from '../util/DataStore';
import SignInLink from '../auth/SignInLink';

export const drawerWidth = 240;
export const [useDrawerOpen] = UseDatum(false);

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
}));
export const TopBar = () => {
  const [open, setOpen] = useDrawerOpen();
  const [isSignedIn] = useIsSignedIn();
  const classes = useStyles();

  return (
    <Toolbar>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="open drawer"
        onClick={() => setOpen(true)}
        className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
        size="large"
      >
        <MenuIcon />
      </IconButton>
      <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
        Our Gate Control
      </Typography>
      <SignInLink />
      {isSignedIn && (
        <IconButton color="inherit" size="large">
          <Badge badgeContent={0} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
      )}
    </Toolbar>
  );
};
