import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import Schedule from '@mui/icons-material/Schedule';
import { useNavigate } from 'react-router-dom';
import { List } from '@mui/material';

export const SideNavMenu = () => {
  const navigate = useNavigate();

  return (
    <List>
      <ListItem button>
        <ListItemIcon onClick={() => navigate('/sites')}>
          <HomeIcon />
        </ListItemIcon>
        <ListItemText primary="Sites" />
      </ListItem>

      <ListItem button>
        <ListItemIcon onClick={() => navigate('/')}>
          <Schedule />
        </ListItemIcon>
        <ListItemText primary="Schedule" />
      </ListItem>
    </List>
  );
};
