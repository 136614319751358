import { FC } from 'react';
import Typography from '@mui/material/Typography';

export const Title: FC = (props) => {
  return (
    <Typography component="h2" variant="h6" color="primary" gutterBottom>
      {props.children}
    </Typography>
  );
};
export default Title;
