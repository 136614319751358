import { Route, Routes } from 'react-router-dom';
import SiteList from './SiteList';
import SignInScreen from '../auth/SignInScreen';
import { useIsSignedIn } from '../util/DataStore';
import Site from './Site';
import Privacy from './Privacy';

export const MainPage = () => {
  const [isSignedIn] = useIsSignedIn();
  return (
    <Routes>
      <Route path="/" element={isSignedIn ? <SiteList /> : SignInScreen} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/login" element={<SignInScreen />} />
      {isSignedIn && <Route path="/site/*" element={<Site />} />}
      {isSignedIn && <Route path="/sites" element={<SiteList />} />}
    </Routes>
  );
};

export default MainPage;
