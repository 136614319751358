import { useEffect, useMemo } from 'react';
import { UseDatum } from 'react-usedatum';
import { Site } from '../shared/OurGateTypes';
import { getDocument } from './FirebaseUtil';

export const [useSelectedSite] = UseDatum('');

export const [useIsSignedIn] = UseDatum(false);
export const [useUserName] = UseDatum('');
export const [useUserEmail, setUserEmail, getUserEmail] = UseDatum('');
export const [useSystemId] = UseDatum('');

export const useSite = (site: string) => {
  const [_useSite, _setSite] = useMemo(() => UseDatum<Site | undefined>(undefined), []);

  useEffect(() => {
    if (site) {
      getDocument<Site>('sites', site)
        .then((doc) => {
          _setSite(doc);
        })
        .catch(() => {});
      return () => {
        //cleanup
      };
    }
  }, [site, _setSite]);
  return _useSite();
};

// export const useDocList = <T = ArgMap>(coll: string, def: T[]) => {
//   const [_useDocList, _setDocList, _getDocList] = UseDatum(def);
//   return useMemo(() => {
//     async function fetchDocList() {
//       console.log('Querying doc list');
//       const docs = await getDocList<T>(coll);
//       _setDocList(docs);
//     }
//     fetchDocList();
//     return _useDocList;
//   }, [coll]);
// };

// export const useSiteList = () => {
//   return useDocList<Site>('sites', [])();
// };
