/* eslint-disable no-script-url */

import React, { useState } from 'react';
import copy from 'clipboard-copy';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PrintIcon from '@mui/icons-material/Print';
import EmailIcon from '@mui/icons-material/Email';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import ClearSearchIcon from '@mui/icons-material/HighlightOff';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Tooltip, TextField, InputAdornment, TableSortLabel, Checkbox, Typography, Link } from '@mui/material';
import { ArgMap, ListConfig, StringField, FieldConfig } from './FormTypes';
import { useEditConfig } from './FormEditRow';
import './DataList.css';
export const CurrencyFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

function Title(props: any) {
  return (
    <Typography component="h2" variant="h6" color="primary" gutterBottom>
      {props.children}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  red: {
    color: 'red',
  },
  formControl: {
    margin: theme.spacing(3),
  },
  print: {
    marginLeft: theme.spacing(3),
  },
  search: {
    marginLeft: theme.spacing(3),
  },
  bordercell: {
    border: 1,
    borderStyle: 'solid',
    borderColor: '#000',
    minWidth: '4em',
  },
  cell: {
    paddingRight: '0.5em',
  },
  smallEmail: { fontSize: '1rem', marginRight: theme.spacing(1) },
  icon: {
    display: 'inlineFlex',
    alignItems: 'center',
    justifyContent: 'center',
    verticalAlign: 'middle',
  },
}));

const getString = (values: ArgMap, path: string, fallback?: string) => {
  fallback = fallback || '';
  if (path.indexOf('.') === -1) {
    const value = values[path];
    return value === undefined ? fallback : String(value);
  }
  const parts = path.split('.');
  for (const key of parts) {
    values = values[key];
    if (values === undefined) {
      return fallback;
    }
  }
  return String(values);
};

interface DataListProps<T = ArgMap<any>> {
  /** Optional title to include with the list */
  title?: string;
  /** Option specification of field types */
  fieldConfig?: FieldConfig;
  listConfig: ListConfig;
  // Data used to render the table
  rows: ArgMap[];
  canEdit?: boolean;
  canDelete?: boolean;
  showCount?: boolean;
  showCheckbox?: boolean;
  onSave?: (row: ArgMap, modified: boolean) => void;
  onCancel?: () => void;
  onAdd?: () => T;
  onDelete?: (row: ArgMap) => void;
  orderBy?: string;
}

const useToast = () => ({ success: (msg: string) => {} });
export default function DataList<T = ArgMap<any>>({
  title,
  fieldConfig,
  listConfig,
  rows,
  canEdit,
  canDelete,
  showCount,
  showCheckbox,
  onSave,
  onCancel,
  onAdd,
  onDelete,
  orderBy: orderByProp,
}: DataListProps<T>) {
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const { success } = useToast();
  const [orderBy, setOrderBy] = useState(orderByProp || '');
  const [order, setOrder] = useState<'desc' | 'asc'>('asc');
  const [selected, setSelected] = useState([] as string[]);
  const [, setEditConfig] = useEditConfig();
  const idcol = listConfig.idcol;

  // ===================== End of hook use ==============================

  const numSelected = selected.length;

  const cellclasses = listConfig.borders
    ? { body: classes.bordercell, head: classes.bordercell }
    : { body: classes.cell };

  const handleSearchChange = (event: any) => {
    setSearch(event.target.value);
  };

  const onClearSearch = () => {
    setSearch('');
  };

  const onRequestSort = (event: any, property: string) => {
    if (orderBy === property) {
      setOrder(order === 'asc' ? 'desc' : 'asc');
    } else {
      setOrderBy(property);
    }
  };

  const onSelectAllClick = (event: any) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((row) => row[idcol]);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;
  const handleRowClick = (event: any, id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [] as string[];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const createSortHandler = (property: string) => (event: any) => {
    onRequestSort(event, property);
  };

  const getAllFields = (row: ArgMap) => {
    const member = row; //membersById[row[Keys.MemberId]];
    if (!member) {
      console.log('Unable to locate member for ' + JSON.stringify(row));
      return row;
    }
    const result = { ...member };
    // for (const key in row) {
    //   const config = DefaultFieldConfig[key];
    //   if (config && config.src === Keys.RegList) {
    //     result[key] = row[key];
    //   }
    // }
    return result;
  };

  // merge fields from member and registration lists
  rows = rows.map((row) => getAllFields(row));

  // If search entered, prune list by looking at display column values
  if (search) {
    const lowerSearch = search.toLowerCase();
    rows = rows.filter((row) =>
      listConfig.dispcols.find((key) =>
        String(row[key] || '')
          .toLowerCase()
          .includes(lowerSearch)
      )
    );
  }
  const rowCount = rows.length;
  const onHeaderClick = (key: string) => () => {
    if (key.toLowerCase().includes('email')) {
      let allowed =
        numSelected === 0 || numSelected === rowCount ? rows : rows.filter((row) => selected.includes(row[idcol]));
      const initialCount = allowed.length;

      // Pull out emails and take only unique items and convert to comma sep list removing empties
      const emails = allowed.map((row) => row[key]).filter((v, i, a) => a.indexOf(v) === i);
      const list = emails.join(',').replace(/,,/g, ',');
      copy(list);
      success(
        `${emails.length}/${initialCount} Emails copied to clipboard. (${allowed.length - emails.length} dups, ${
          initialCount - allowed.length
        } excluded)`
      );
    }
  };

  const onEditRow = (row: ArgMap) => () => {
    console.log('onEditRow');
    if (canEdit) {
      setEditConfig({ listConfig, fieldConfig, open: true, values: row, onCancel, onSave });
    }
  };

  const onAddClicked = () => {
    if (onAdd) {
      const template = onAdd();
      setEditConfig({ listConfig, fieldConfig, open: true, values: template, onCancel, onSave });
    }
  };

  const onDeleteRow = (row: ArgMap) => {
    if (onDelete) {
      onDelete(row);
    }
  };

  // sort fields
  if (orderBy) {
    rows = rows.sort((a, b) => {
      const aval = getString(a, orderBy);
      const bval = getString(b, orderBy);
      let cmp = aval.localeCompare(bval, undefined, {
        numeric: true,
        sensitivity: 'base',
      });
      if (order === 'desc') cmp = cmp * -1;
      return cmp;
    });
  }

  let printRow = 0;
  const hasMultipleRows = rows.length > 1;

  return (
    <React.Fragment>
      <Box flexGrow={1} overflow="auto" display="flex" flexDirection="column">
        <Box display="flex" flexDirection="row" displayPrint="none">
          {title && <Title>{title}</Title>}
          {hasMultipleRows && (
            <Tooltip title="Print" placement="bottom">
              <PrintIcon className={classes.print} onClick={() => window.print()} />
            </Tooltip>
          )}
          {hasMultipleRows && (
            <TextField
              variant="standard"
              className={classes.search}
              value={search}
              onChange={handleSearchChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip title="Clear Search" placement="bottom">
                      <ClearSearchIcon style={{ color: '#888' }} onClick={onClearSearch} />
                    </Tooltip>
                  </InputAdornment>
                ),
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          )}
          {onAdd && canEdit && (
            <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
              <Fab size="small" color="primary" aria-label="add" onClick={onAddClicked}>
                <AddIcon />
              </Fab>
            </Box>
          )}
        </Box>

        <Table size="small">
          <TableHead>
            <TableRow>
              {showCheckbox && (
                <TableCell padding="checkbox" className="noprint">
                  <Checkbox
                    indeterminate={numSelected > 0 && numSelected < rowCount}
                    checked={numSelected === rowCount}
                    onChange={onSelectAllClick}
                    inputProps={{ 'aria-label': 'Select all desserts' }}
                  />
                </TableCell>
              )}
              {canEdit && (
                <TableCell padding="none" className="noprint" classes={cellclasses}>
                  Action
                </TableCell>
              )}
              {canDelete && <TableCell padding="none" className="noprint" classes={cellclasses}></TableCell>}
              {showCount && (
                <TableCell padding="none" classes={cellclasses}>
                  <Box display="flex" flexDirection="row" displayPrint="none">{`Count (${rows.length})`}</Box>
                </TableCell>
              )}
              {listConfig.dispcols.map((col, i) => (
                <TableCell padding="none" key={i} classes={cellclasses} sortDirection={orderBy === col ? order : false}>
                  {col.toLowerCase().includes('email') && (
                    <Tooltip title="Copy emails to clipboard" placement="bottom">
                      <EmailIcon
                        className={clsx(classes.icon, 'noprint')}
                        fontSize="small"
                        classes={{ fontSizeSmall: classes.smallEmail }}
                        onClick={onHeaderClick(col)}
                      />
                    </Tooltip>
                  )}
                  <TableSortLabel active={orderBy === col} direction={order} onClick={createSortHandler(col)}>
                    {fieldConfig?.[col]?.title || col}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, rowIndex) => {
              const rowId = row[idcol] || rowIndex;
              const isItemSelected = isSelected(rowId);
              const labelId = `enhanced-table-checkbox-${rowId}`;
              // hide row when printing
              const noprint = !isItemSelected && numSelected !== 0 && numSelected !== rowCount;
              const rowClassName = noprint ? 'noprint' : '';
              if (!noprint) {
                printRow = printRow + 1;
              }
              const onEditRowClicked = onEditRow(row);
              return (
                <TableRow
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={rowId}
                  selected={isItemSelected}
                  className={rowClassName}
                  onDoubleClick={onEditRowClicked}
                >
                  {showCheckbox && (
                    <TableCell
                      padding="checkbox"
                      className="noprint"
                      onClick={(event: any) => handleRowClick(event, rowId)}
                    >
                      <Checkbox checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} />
                    </TableCell>
                  )}
                  {canEdit && (
                    <TableCell padding="none" className="noprint" classes={cellclasses}>
                      <Tooltip title="Edit this entry">
                        <EditIcon onClick={onEditRowClicked} />
                      </Tooltip>
                    </TableCell>
                  )}
                  {canDelete && (
                    <TableCell padding="none" className="noprint" classes={cellclasses}>
                      <Tooltip title="Delete this entry">
                        <DeleteIcon onClick={() => onDeleteRow(row)} />
                      </Tooltip>
                    </TableCell>
                  )}

                  {/* <Box display="none" displayPrint="flex"> */}
                  {showCount && (
                    <TableCell padding="none" className="noscreen" classes={cellclasses}>
                      {printRow}
                    </TableCell>
                  )}
                  {/* </Box> */}
                  {showCount && (
                    <TableCell padding="none" className="noprint" classes={cellclasses}>
                      {rowIndex + 1}
                    </TableCell>
                  )}
                  {listConfig.dispcols.map((col, i) => {
                    const config = fieldConfig?.[col] || ({ type: 'string' } as StringField);
                    let val: any = getString(row, col);
                    if (config.type === 'timestamp') {
                      let d = new Date(Number(val));
                      const valid = d instanceof Date && !!d.getDate();
                      if (!valid) {
                        d = new Date(String(val));
                      }
                      val = d.toLocaleString('en-CA');
                    } else if (config.type === 'date') {
                      if ((typeof val === 'string' && val.includes('T')) || typeof val === 'number') {
                        const d = new Date(val);
                        val = d.toLocaleString('en-CA');
                      }
                    } else if (config.type === 'checkbox') {
                      // val = val === 'true' ? 'X' : '';
                      val = <Checkbox checked={val === 'true'} />;
                    } else if (config.type === 'currency') {
                      val = CurrencyFormatter.format(Number(val));
                    }
                    if (config.type === 'checkbox') {
                      // val = val === 'true' ? 'X' : '';
                      val = <Checkbox checked={val === 'true'} />;
                    }
                    if (config.link) {
                      // eslint-disable-next-line no-template-curly-in-string
                      const link = config.link.replace('${value}', String(val));
                      if (link.startsWith('/')) {
                        val = (
                          <Link component={RouterLink} color="primary" to={link}>
                            {config.text || val}
                          </Link>
                        );
                      } else {
                        val = (
                          <Link color="primary" href={link} target="_blank" rel="noopener">
                            {val}
                          </Link>
                        );
                      }
                    }
                    return (
                      <TableCell padding="none" key={i} classes={cellclasses}>
                        {val}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
    </React.Fragment>
  );
}
